export type TimeRange = "DAY" | "WEEK" | "MONTH" | "THREE_MONTH" | "YEAR" | "ALL";

export type TimeBlockSize = "HOUR" | "DAY";

interface SessionFilter {
  tenantId: string;
  locationId?: string;
  stationId?: string;
}

export interface SessionMetricsFilter extends SessionFilter {
  timeRange: TimeRange;
}

export interface TimeSeriesFilter {
  from: string;
  until?: string;
  timezone: string;
  timeBlockSize: TimeBlockSize;
}

export interface SessionTimeSeriesFilter extends SessionFilter, TimeSeriesFilter {}

export interface SessionMetricsResponse {
  activeSessions: SessionMetricsValue;
  totalSessions: SessionMetricsValue;
  totalPower: SessionMetricsValue;
  totalRevenue: SessionMetricsValue;
  currency?: string;
}

export interface SessionMetricsValue {
  value: number;
  report?: number;
  status?: string;
}

export const emptySessionMetricsResponse: SessionMetricsResponse = {
  activeSessions: { value: 0 },
  totalSessions: { value: 0 },
  totalPower: { value: 0 },
  totalRevenue: { value: 0 },
  currency: undefined,
};

export type SessionChartResponse = Array<SessionChartValue>;

export interface SessionChartValue {
  dateTime: string;
  value: number;
}

export type TimeSeries = {
  items: Array<TimeSeriesItem>;
};

export type TimeSeriesItem = {
  dateTime: string;
  value: number;
};

export interface SessionExportParams {
  timezone: string;
  startTimeFrom: string;
  startTimeTo: string;
  tenantId: string;
  locationId?: string;
  stationId?: string;
  rfid?: string;
}

export interface SessionResponse {
  id: string;
  startTime: string;
  endTime?: string;
  evseId: string;
  locationId: string;
  locationName: string;
  ocppStationId: string;
  initiatedBy?: string;
  wattHour: number;
  status: string;
  cost: number;
  tariffId?: string;
  tariffName?: string;
  currency?: string;
  stopReason?: string;
  evseEmi3Id?: string;
  energyCost?: number;
  parkingCost?: number;
  parkingTime?: string;
}

export const sessionResponseEmpty = {
  id: "",
  startTime: "",
  endTime: undefined,
  evseId: "",
  locationId: "",
  locationName: "",
  ocppStationId: "",
  initiatedBy: "",
  wattHour: 0,
  status: "",
  cost: 0,
  tariffId: undefined,
  tariffName: undefined,
  currency: undefined,
  stopReason: undefined,
  evseEmi3Id: undefined,
  energyCost: 0,
  parkingCost: 0,
  parkingTime: "",
};
