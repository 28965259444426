import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getStationUptime } from "src/api/stations"; // Assume the function is available
import { getFromDateByRange, getTimeBlockSizeByRange } from "../sessions/range-utilities";

interface UptimeIntervals {
  uptime24h: number | null;
  uptime7d: number | null;
  uptime30d: number | null;
}

interface StationUptimeMetricsProps {
  stationId: string;
}

const StationUptimeMetrics: FC<StationUptimeMetricsProps> = ({ stationId }) => {
  const [uptimes, setUptimes] = useState<UptimeIntervals>({
    uptime24h: null,
    uptime7d: null,
    uptime30d: null,
  });

  const [loading, setLoading] = useState(true);

  const fetchUptimeData = async (range: "1D" | "1W" | "1M") => {
    try {
      const response = await getStationUptime(stationId, {
        from: getFromDateByRange(range),
        timezone: dayjs.tz.guess(),
        timeBlockSize: getTimeBlockSizeByRange(range),
      });
      return response.data.totalAverage;
    } catch (err) {
      return 0;
    }
  };

  useEffect(() => {
    const fetchAllUptimes = async () => {
      setLoading(true);

      const uptime24h = await fetchUptimeData("1D");
      const uptime7d = await fetchUptimeData("1W");
      const uptime30d = await fetchUptimeData("1M");

      setUptimes({
        uptime24h,
        uptime7d,
        uptime30d,
      });

      setLoading(false);
    };

    fetchAllUptimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  const processedUptimes = {
    uptime24h: uptimes.uptime24h ?? 0,
    uptime7d: uptimes.uptime7d ?? 0,
    uptime30d: uptimes.uptime30d ?? 0,
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h3" align="left" marginBottom={2}>
            <FormattedMessage id="uptime" />
          </Typography>

          <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" marginBottom={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body1" margin={1}>
                <FormattedMessage id="last24Hours" />
              </Typography>
              <Typography variant="h2" margin={1} marginRight={0}>
                {`${processedUptimes.uptime24h}%`}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body1" margin={1}>
                <FormattedMessage id="last7Days" />
              </Typography>
              <Typography variant="h2" margin={1} marginRight={0}>
                {`${processedUptimes.uptime7d}%`}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body1" margin={1}>
                <FormattedMessage id="last30Days" />
              </Typography>
              <Typography variant="h2" margin={1} marginRight={0}>
                {`${processedUptimes.uptime30d}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StationUptimeMetrics;
