import { DisplayText } from "../common/interfaces";
import { TimeSeries, TimeSeriesFilter } from "../sessions/interfaces";

export interface StationSummaryFilter {
  locationId?: string;
  tenantId: string;
}

export interface StationSummaryResponse {
  id: string;
  ocppStationId: string;
  fullModelName: string;
  status: string;
  evses: Array<EvseSummaryResponse>;

  locationId: string;
  locationName: string;
}

export interface EvseSummaryResponse {
  id: string;
  powerType: string;
  powerMaxWattage: number;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: string;
  connectors: Array<ConnectorSummaryResponse>;
  activeTariffId: string;
  activeTariffName: string;
}

export interface ConnectorSummaryResponse {
  kind: string;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: string;
}

export interface StationDetailsResponse {
  id: string;
  locationId: string;
  locationName: string;
  ocppStationId: string;
  ocppStationPwd: string;
  brand: string;
  model: string;
  additionalInformation: string;
  directions: Array<DisplayText>;
  roamingEnabled: boolean;
  reservationEnabled: boolean;
  network: string;
  powerMaxWattage: number;
  status: string;
  emi3Name: string;
  evses: Array<EvseDetailsResponse>;
  profile: StationProfileDetailsResponse;
}

export enum StationDetailsStatus {
  inoperative = "INOPERATIVE",
  active = "ACTIVE",
  outOfOrder = "OUTOFORDER",
  decomissioned = "DECOMISSIONED",
}

export interface EvseDetailsResponse {
  id: string;
  emi3Id: string;
  specifications: EvseSpecificationsDTO;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: string;
  connectors: Array<ConnectorDetailsResponse>;
  deleted: boolean;
  activeTariffId: string;
  activeTariffName: string;
  qrCodeId: string;
}

export interface EvseSpecificationsDTO {
  powerType: string;
  powerMaxCurrent: number;
  powerMaxVoltage: number;
  powerMaxWattage: number;
}

export interface ConnectorDetailsResponse {
  id: string;
  specifications: ConnectorSpecificationsDTO;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: string;
}

export interface ConnectorSpecificationsDTO {
  kind: string;
  format: string;
  alias?: string;
}

export interface StationProfileDetailsResponse {
  enableConnectorZeroEmulation: boolean;
  useHeartbeatAsPing: boolean;
}

export const stationDetailsResponseEmpty: StationDetailsResponse = {
  id: "",
  locationId: "",
  locationName: "",
  ocppStationId: "",
  ocppStationPwd: "",
  brand: "",
  model: "",
  additionalInformation: "",
  directions: [],
  roamingEnabled: false,
  reservationEnabled: false,
  network: "",
  powerMaxWattage: 0,
  status: "",
  emi3Name: "",
  evses: [],
  profile: {
    enableConnectorZeroEmulation: false,
    useHeartbeatAsPing: false,
  },
};

export interface StationUpsertRequest {
  id: string;
  locationId: string;
  tenantId: string;
  ocppStationId: string;
  ocppStationPwd?: string;
  brand: string;
  model: string;
  additionalInformation: string;
  roamingEnabled: boolean;
  reservationEnabled: boolean;
  network: string;
  powerMaxWattage: number;
  emi3Name: string;
  evses: Array<EvseUpsertRequest>;
  profile: StationProfileUpsertRequest;
  directions?: Array<DisplayText>;
}

export interface EvseUpsertRequest {
  id?: string;
  qrCodeId: String;
  specifications: EvseSpecificationsDTO;
  connectors: Array<ConnectorUpsertRequest>;
}

export interface ConnectorUpsertRequest {
  id?: string;
  specifications: ConnectorSpecificationsDTO;
}

export interface EvseStartRequest {
  id: string;
  evseId: string;
  token?: string;
}

export interface EvseStopRequest {
  id: string;
  evseId: string;
}

export interface StationResetRequest {
  id: string;
  resetType: "soft" | "hard";
}

export interface StationProfileUpsertRequest {
  enableConnectorZeroEmulation: boolean;
  useHeartbeatAsPing: boolean;
}

export interface StationChangeAvailabilityRequest {
  id: string;
  toAvailable: boolean;
}

export interface StationChangeAvailabilityResponse {
  code: string;
  details: {
    message: string;
  };
}

export enum ChangeAvailResponseCode {
  scheduled = "CHANGE_AVAILABILITY_SCHEDULED",
  accepted = "CHANGE_AVAILABILITY_ACCEPTED",
}

export interface StationConfigRequest {
  id: string;
  configuration?: Array<StationConfigItemDTO>;
}

export interface StationConfigItemDTO {
  key: string;
  value: string;
}

export interface StationConfigResponse {
  configuration: Array<StationConfigResultDTO>;
}

export interface StationConfigResultDTO {
  key: string;
  value: string;
  targetValue: string;
  status: string;
  readOnly: boolean;
}

export interface StationComponentsResponse {
  templates: Array<StationTemplateResponse>;
  networkTypes: Array<string>;
  connectorTypes: Array<ConnectorTypeResponse>;
  connectorFormats: Array<string>;
  powerTypes: Array<PowerTypeResponse>;
}

export interface StationTemplateResponse {
  brand: string;
  model: string;
  evses: Array<EvseTemplateResponse>;
  profile: StationProfileTemplateResponse;
}

export interface EvseTemplateResponse {
  specifications: EvseSpecificationsDTO;
  connectors: Array<ConnectorSpecificationsDTO>;
}

export interface ConnectorTypeResponse {
  name: string;
}

export interface PowerTypeResponse {
  name: string;
  simpleName: string;
}

export interface StationProfileTemplateResponse {
  enableConnectorZeroEmulation: boolean;
  useHeartbeatAsPing: boolean;
}

export interface StationTimeSeriesFilter extends TimeSeriesFilter {}

export interface StationUptimeResponse {
  totalAverage: number;
  graph: TimeSeries;
}
